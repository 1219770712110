import { Tag } from 'primereact/tag'
import React from 'react'
import { Link } from 'react-router-dom'

const WALinkTemplate = ({data}) => {
  return (
    <Link to={`https://wa.me/${data}`} target='_blank'><Tag icon="pi pi-whatsapp" value={data} severity="success"/></Link>
  )
}

export default WALinkTemplate
