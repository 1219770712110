import { Button } from 'primereact/button';
import { Card } from 'primereact/card'
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable'
import React, { useEffect, useState } from 'react'
import { formattedDateWithTimeShort } from '../../../Hooks/helper';
import FormAddStudent from './FormAddStudent';
import RemoveStudent from './RemoveStudent';
import UpdateSessionSchedule from './UpdateSessionSchedule';
import UpdateDetailSession from './UpdateDetailSession';
import InfoStudent from './InfoStudent';

const Attendance = ({
  listAttendance,
  toast,
  classStatus,
  refreshAction,
  loading, 
  setLoading,
  sessionLength,
  detailClass
}) => {
  // Data Handler 
  const [ attendance, setAttendance ] = useState([]);
  const [ selectedSessionCell, setSelectedSessionCell ] = useState(null);
  const [ nameFroze, setNameFroze ] = useState(false);
  const [ detailAttendance, setDetailAttendance ] = useState({});
  const [ scheduleSession, setScheduleSession ] = useState({});
  const [ detailSession, setDetailSession ] = useState({
    attendanceId: "",
    sessionIndex: "",
    newStatus: "",
    review: "",
    studentName: "",
    moodBefore: 0,
    moodAfter: 0
  });

  // Dialog Handler
  const [ sessionUpdateDialog, setSessionUpdateDialog ] = useState(false);
  const [ removeStudentDialog, setRemoveStudentDialog ] = useState(false);
  const [ scheduleSessionDialog, setScheduleSessionDialog ] = useState(false);
  const [ detailSessionDialog, setDetailSessionDialog ] = useState(false);
  const [ detailStudentDialog, setDetailStudentDialog ] = useState(false);

  useEffect(() => {
    if (listAttendance) setAttendance(listAttendance);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listAttendance]);

  const showDialog = (type, data = {}) => {
    if (type === "ADD_STUDENT") { 
      setSessionUpdateDialog(true);
    }

    if (type === "REMOVE_STUDENT") {
      setDetailAttendance(data);
      setRemoveStudentDialog(true);
    }

    if (type === "UPDATE_SESSION_SCHEDULE")  {
      setScheduleSession(data);
      setScheduleSessionDialog(true);
    }

    if (type === "UPDATE_DETAIL_SESSION") {  
      const { rowData, column, value } = data;
      const index = column.key.split('$')[1];      

      if (data.field === "student") {
        setDetailAttendance(rowData);
        setDetailStudentDialog(true);
      } else {
        setDetailSession({
          attendanceId: rowData.id,
          sessionIndex: index,
          newStatus: value,
          review: rowData[`review${index}`],
          studentName: rowData.student,
          moodBefore: rowData[`moodBefore${index}`],
          moodAfter: rowData[`moodAfter${index}`]
        });
  
        setDetailSessionDialog(true);
      }
    }
  }

  const hideDialog = (type) => {
    if (type === "ADD_STUDENT") {
      setSelectedSessionCell(null);
      setSessionUpdateDialog(false);
    }

    if (type === "REMOVE_STUDENT") {
      setDetailAttendance({});
      setRemoveStudentDialog(false);
    }

    if (type === "UPDATE_SESSION_SCHEDULE") {
      setScheduleSessionDialog(false);
    }

    if (type === "UPDATE_DETAIL_SESSION") {
      setDetailSession({
        attendanceId: "",
        sessionIndex: "",
        newStatus: "",
        review: "",
        studentName: "",
        moodBefore: 0,
        moodAfter: 0
      });

      setSelectedSessionCell(null);
      setDetailSessionDialog(false);
    }

    if (type === "DETAIL_STUDENT") {
      setSelectedSessionCell(null);
      setDetailAttendance({});
      setDetailStudentDialog(false);
    }
  }

  const actionBodyTemplate = (data) => (
    <Button icon="pi pi-trash" outlined severity='danger' onClick={() => showDialog("REMOVE_STUDENT", data)} />
  );

  const attendanceSessionColumn = () => {
    const column = [];
    
    if (attendance !== null && attendance.length > 0) {
       for(let index = 1; index <= sessionLength; index++) {
        column.push( <Column 
          key={index} 
          field={`sesi`+index} 
          header={() => headerSessionColumnTemplate(index, attendance[0][`tanggalSesi${index}`])} 
          style={{minWidth: '10rem'}}
          align="center"
        />);
       }
    }

    return column;
  };

  const headerSessionColumnTemplate = (index, scheduledDate) => (
    <div className='text-center'>
      Sesi {index}
      <p className='lg:text-sm md:text-sm sm:text-sm text-xs font-light font-normal p-0 m-0'>{formattedDateWithTimeShort(scheduledDate)}</p>
      {
        (classStatus !== "Selesai" && !attendance[0][`statusGaji${index}`]) &&
        <span><i className='pi pi-pencil text-blue-500 text-sm cursor-pointer mt-1' onClick={() => showDialog("UPDATE_SESSION_SCHEDULE", { 
          subject: `${detailClass.kodeBatch}.${detailClass.kodeKelas} ${detailClass.namaKelas} - ${detailClass.jenisKelas} (Lvl. ${detailClass.level})`,
          indexSesi: parseInt(index) -1,
          jadwalSesi: scheduledDate,
          classId: detailClass._id
        })}></i></span>
      }
    </div>
  );

  const headerTableTemplate = () => (
    <div className='flex lg:flex-row md:flex-row sm:flex-row flex-column justify-content-between align-items-center gap-2'>
      <h2 className='m-0 p-0 text-center'>Attendance</h2>
      <Button label='+ Student' size='small' className={classStatus === "Selesai" ? 'hidden' : 'lg:w-max md:w-max sm:w-max w-full'} disabled={classStatus === "Selesai"} onClick={() => showDialog("ADD_STUDENT", null)}/>
    </div>
  )

  const isCellAttendanceSelectable = (event) => {
    const cellFieldDisabled = ["nie", "action"];
    let field = event.data.field;

    if (classStatus !== "Selesai") return !cellFieldDisabled.includes(field);
    if (classStatus === "Selesai") return false;
  }

  const nameHeaderTemplate = () => (
    <div className='flex align-items-center'>
      Nama Siswa
      <i className={`pi ${nameFroze ? 'pi-lock-open' : 'pi-lock'} cursor-pointer font-bold ml-4`} onClick={() => setNameFroze(!nameFroze)}></i>
    </div>
  )

  return (
    <Card>
      <FormAddStudent 
        visible={sessionUpdateDialog}
        visibleHandler={() => hideDialog("ADD_STUDENT")}
        refreshAction={refreshAction}
        loading={loading}
        setLoading={setLoading}
        toast={toast}
        dataClass={detailClass}
      />

      <RemoveStudent
        visible={removeStudentDialog}
        visibleHandler={() => hideDialog("REMOVE_STUDENT")}
        refreshAction={refreshAction}
        setLoading={setLoading}
        toast={toast}
        detailAttendance={detailAttendance}
      />

      <UpdateSessionSchedule
        visibility={scheduleSessionDialog}
        visibleHandler={() => hideDialog("UPDATE_SESSION_SCHEDULE")}
        detailSession={scheduleSession}
        loading={loading}
        setLoading={setLoading}
        refreshAction={refreshAction}
        toast={toast}
      />

      <UpdateDetailSession
        visible={detailSessionDialog}
        visibleHandler={() => hideDialog("UPDATE_DETAIL_SESSION")}
        refreshAction={refreshAction}
        loading={loading}
        setLoading={setLoading}
        toast={toast}
        detailSession={detailSession}
      />

      <InfoStudent
        visible={detailStudentDialog}
        visibleHandler={() => hideDialog("DETAIL_STUDENT")}
        detailStudent={detailAttendance}
      />

      <DataTable
        value={attendance}
        scrollable
        cellSelection
        selectionMode='single'
        selection={selectedSessionCell}
        onSelectionChange={(e) => setSelectedSessionCell(e.value)}
        metaKeySelection={false}
        onCellSelect={(e) =>  showDialog("UPDATE_DETAIL_SESSION", e)}
        isDataSelectable={isCellAttendanceSelectable}
        removableSort
        loading={loading}
        header={headerTableTemplate}
        className='lg:text-sm md:text-sm sm:text-sm text-xs'
        size='small'
      >
        <Column field='student' header={nameHeaderTemplate} frozen={nameFroze} sortable style={nameFroze  ? {minWidth: '8rem'} : { minWidth: '12rem'}}/>
        <Column field='nie' header="NIE" sortable alignHeader="center" />
        {attendanceSessionColumn()}
        { classStatus !== "Selesai"  &&  <Column field='action' body={actionBodyTemplate} />}
      </DataTable>
    </Card>
  )
}

export default Attendance
