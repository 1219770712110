import { Dialog } from 'primereact/dialog'
import React from 'react'
import WALinkTemplate from '../../../Components/Tag/WALinkTemplate'

const InfoStudent = ({
  visible,
  visibleHandler,
  detailStudent
}) => {
  return (
    <Dialog
        visible={visible}
        onHide={visibleHandler}
        header="Detail Student"
        style={{ width: '20rem' }} 
        breakpoints={{'960px': '45vw', '640px': '90vw'}}
    >
        <div className='flex flex-column gap-2 w-full'>
            <div className='flex align-items-center gap-3'>
                <i className='pi pi-user text-4xl lg:block md:block sm:block hidden'></i>
                <div>
                    <label className='flex align-items-center font-bold'><i className='pi pi-user font-bold lg:hidden md:hidden sm:hidden mr-2'></i>Student Name</label>
                    <p className='p-0 m-0 flex align-items-center gap-1'>{detailStudent.student}</p>
                </div>
            </div>
            <div className='flex align-items-center gap-3'>
                <i className='pi pi-wave-pulse text-4xl lg:block md:block sm:block hidden'></i>
                <div>
                    <label className='flex align-items-center font-bold'><i className='pi pi-wave-pulse font-bold lg:hidden md:hidden sm:hidden mr-2'></i>Age</label>
                    <p className='p-0 m-0'>{detailStudent.usia} years old</p>
                </div>
            </div>
            <div className='flex align-items-center gap-3'>
                <i className='pi pi-whatsapp text-4xl lg:block md:block sm:block hidden'></i>
                <div>
                    <label className='flex align-items-center font-bold'><i className='pi pi-whatsapp font-bold lg:hidden md:hidden sm:hidden mr-2'></i>Contact</label>
                    <WALinkTemplate data={detailStudent.nomor}/>
                </div>
            </div>
            <div className='flex align-items-center gap-3'>
                <i className='pi pi-users text-4xl lg:block md:block sm:block hidden'></i>
                <div>
                    <label className='flex align-items-center font-bold'><i className='pi pi-users font-bold lg:hidden md:hidden sm:hidden mr-2'></i>Mother's Name</label>
                    <p className='p-0 m-0'>{detailStudent.ibu}</p>
                </div>
            </div>
        </div>
    </Dialog>
  )
}

export default InfoStudent
