import React from 'react'
import NameCard from './NameCard'

const Help = () => {
  return (
    <div className='h-screen pt-5'>
      <div className='flex flex-column justify-content-center align-items-center py-5'>
        <i className='pi pi-briefcase lg:text-7xl text-6xl text-700 mt-5'/>
        <h1 className='mx-0 mt-0 mb-3 p-0 lg:text-4xl md:text-4xl sm:text-3xl text-xl text-700'>IT Developer Team</h1>
        <div className='flex lg:flex-row md:flex-row sm:flex-row flex-column gap-3 align-items-center justify-content-center'>
          <NameCard 
            name="Asyam Faiq"
            note="Insyaallah fast response. mohon untuk menyantumkan error atau problem yang ada pada Edufic Portal, lebih baik jika disertai dengan screenshot. Semoga Harimu menyenangkan :)"
            wa="https://wa.me/6282244898420"
          />
          <NameCard
            name="Alfikiyar Tirta Haidar"
            note="Feel free aja buat share problem, jika mendesak berkenan untuk ditelpon. Isyaallah bisa solve, kalau belum solve mohon bersabar karena selalu ada jalan pada setiap masalah. Enjoy your time ..."
            wa="https://wa.me/6282244898420"
          />
        </div>
      </div>
    </div>
  )
}

export default Help
